<template>
  <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white">
    <div class="px-4 py-5 border-b border-solid border-gray-200">
      <div class="flex items-center justify-between">
        <h3 class="font-bold text-xl text-gray-800">{{$t('metadata.metadata_extraction')}}</h3>
        <div class="flex items-center">
          <!-- Filename Display -->
          <span v-if="file" class="mr-3 text-sm font-medium text-gray-700">{{ file.name }}</span>
          
          <div style="margin-left: 20px; margin-right: 20px;" v-if="isLoading" class="flex items-center">
            <!-- Loading Spinner with Visible Gap -->
            <half-circle-spinner
              :animation-duration="1000"
              :size="60"
              color="#4CAF50"
            />
            <span style="margin-left: 20px;" class="text-gray-700 text-sm">{{$t('metadata.generating_metadata')}}</span>
          </div>
          <div v-else-if="metadataGenerated" class="flex items-center">
            <!-- Success State -->
            <svg class="h-6 w-6 mr-2 text-emerald-500" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path>
            </svg>
            <span class="text-gray-700 text-sm">{{$t('metadata.metadata_generated')}}</span>
          </div>
          <div v-else class="flex items-center">
            <!-- Default State -->
            <input type="file" id="file" @change="onFileChange" accept=".zip" class="file-input">
            <label for="file" class="cursor-pointer bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:bg-emerald-600 focus:outline-none transition ease-in duration-150">
              {{$t('metadata.select_file')}}
            </label>
            <button class="ml-3 bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:bg-emerald-600 focus:outline-none transition ease-in duration-150" @click="extractTopics">
              {{$t('metadata.upload_process_file')}}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="block w-full overflow-x-auto">
      <!-- Projects table -->
      <table class="w-full divide-y divide-gray-200">
        <thead class="bg-gray-50">
          <tr>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {{$t('metadata.filename')}}
            </th>
            <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              {{$t('metadata.actions')}}
            </th>
          </tr>
        </thead>
        <tbody class="bg-white divide-y divide-gray-200">
          <tr v-for="file in files" :key="file.name" class="hover:bg-gray-50">
            <td class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
              {{ file.name }}
            </td>
            <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
              <button class="text-emerald-600 hover:text-emerald-900" @click="downloadFile(file.url)">{{$t('metadata.download')}}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>


<script>
import axios from 'axios';
import {HalfCircleSpinner} from 'epic-spinners'

export default {
  components: {
    HalfCircleSpinner
  },
  data() {
    return {
      file: null,
      files: [],
      isLoading: false,
      metadataGenerated: false
    };
  },
  methods: {
    onFileChange(event) {
      this.file = event.target.files[0];
    },
    async extractTopics() {
      if (!this.file) {
        alert(this.$t('metadata.select_file_first'));
        return;
      }
      const formData = new FormData();
      formData.append('file', this.file);

      this.isLoading = true;
      try {
        const response = await axios.post('https://conan.antsolutions.be/conanbe/process_archive/', formData);
        this.files = response.data.files;
        this.isLoading = false;
        this.metadataGenerated = true;
        setTimeout(() => this.metadataGenerated = false, 5000);  // Reset the success message after 5 seconds
      } catch (error) {
        this.isLoading = false;
        console.error('Error:', error);
        alert(this.$t('metadata.failed_to_process_file'));
      }
    },
    downloadFile(url) {
      const link = document.createElement('a');
      link.href = url;
      link.download = url.split('/').pop();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

  <style scoped>
  .file-input {
    cursor: pointer;
    color: transparent;
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .file-input + label {
    font-size: 14px;
    font-weight: 700;
    color: white;
    background-color: #4CAF50;
    display: inline-block;
    transition: background-color 0.3s;
    cursor: pointer;
    padding: 10px 20px;
  }
  .file-input:focus + label,
  .file-input + label:hover {
    background-color: #43A047;
  }
  .file-input + label {
    cursor: pointer; /* "hand" cursor */
  }
  </style>
  