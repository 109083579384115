import { createI18n } from 'vue-i18n';
import nl from './locales/nl.json';
import fr from './locales/fr.json';
import axios from 'axios';

const messages = {
  nl,
  fr
};

let userLocale = 'nl'; // Default to Dutch

console.log('Initializing locale with default:', userLocale);

// Create i18n instance with default locale 'nl'
const i18n = createI18n({
  locale: userLocale, // Default language from user details
  fallbackLocale: 'nl', // Fallback language if requested translation is missing
  messages,
  globalInjection: true, // Enable global injection
});

console.log('i18n instance created with locale:', userLocale);

// Fetch the user details to determine the locale
const token = localStorage.getItem('user-token');
if (token) {
  console.log('User token found:', token);
  axios.get('https://conan.antsolutions.be/conanbe/getCurrentUserDetails', {
    headers: { Authorization: `Token ${token}` },
  })
  .then(response => {
    console.log('User details response:', response);
    if (response.data.language) {
      userLocale = response.data.language;
      console.log('User language found, setting locale to:', userLocale);
      // Update the i18n instance locale dynamically
      i18n.global.locale = userLocale;
      console.log('i18n locale updated to:', userLocale);
    } else {
      console.log('No language specified in user details, using default:', userLocale);
    }
  })
  .catch(error => {
    console.error('Error fetching user language:', error);
  });
} else {
  console.log('No user token found, using default locale:', userLocale);
}

export default i18n;