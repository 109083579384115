<template>
  <div class="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
    <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
      <div class="flex flex-wrap items-center">
        <div class="relative w-full max-w-full flex-grow flex-1">
          <h6 class="uppercase text-blueGray-400 mb-1 text-xs font-semibold">
            {{ chartTitle }}
          </h6>
          <h2 class="text-blueGray-700 text-xl font-semibold">
            {{ currentChartSubtitle }}
            <i v-if="alternateChartSubtitle.length > 1" class="fa fa-refresh cursor-pointer" style="font-size: 0.8em;" @click="toggleChartData"></i>
          </h2>
        </div>
      </div>
    </div>
    <div class="p-4 flex-auto relative">
      <div v-if="loading">
        <Skeleton height="250px" />
      </div>
      <div v-else>
        <donut-chart :data="currentChartData" :options="chartOptions"></donut-chart>
        <div v-if="!isDataAvailable" class="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
          <span class="text-center text-gray-500">
            {{ $t('graphs.no_data_available') }}<br/><br/><br/>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs';
import { Skeleton } from 'vue-loading-skeleton';
import 'vue-loading-skeleton/dist/style.css';

export default {
  components: {
    DonutChart: Doughnut,
    Skeleton,
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({}),
    },
    chartTitle: {
      type: String,
      default: 'Donut Chart',
    },
    chartSubtitle: {
      type: String,
      default: 'Distribution',
    },
    alternateChartData: {
      type: Object,
      default: () => ({}),
    },
    alternateChartSubtitle: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAlternateData: false,
    };
  },
  computed: {
    isDataAvailable() {
      return this.currentChartData.datasets.some(
        (dataset) => dataset.data && dataset.data.length > 0
      );
    },
    currentChartData() {
      return this.isAlternateData ? this.alternateChartData : this.chartData;
    },
    currentChartSubtitle() {
      return this.isAlternateData ? this.alternateChartSubtitle : this.chartSubtitle;
    },
    chartOptions() {
      return {
        maintainAspectRatio: false,
        responsive: true,
        cutout: '50%',
        legend: {
          position: 'bottom',
          labels: {
            fontColor: 'rgba(0,0,0,.4)',
          },
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem, data) => {
              let label = data.labels[tooltipItem.index] || '';
              if (label) {
                label += ': ';
              }
              const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

              if (this.isAlternateData) {
                const mbValue = (value / 1024).toFixed(2);
                label += `${mbValue} MB`;
              } else {
                label += value;
              }
              return label;
            },
          },
        },
      };
    },
  },
  methods: {
    toggleChartData() {
      this.isAlternateData = !this.isAlternateData;
    },
  },
};
</script>