<template>
  <div
    class="relative flex flex-col min-w-0 break-words rounded mb-6 xl:mb-0 shadow-lg"
    :class="[backgroundColor]"
  >
    <div class="flex-auto p-4">
      <div v-if="loading">
        <Skeleton :count="1" height="100px" />
      </div>
      <div v-else>
        <div class="flex flex-wrap">
          <div class="relative w-full pr-4 max-w-full flex-grow flex-1">
            <h5 class="text-blueGray-400 uppercase font-bold text-xs">
              {{ statSubtitle }}
            </h5>
            <span class="font-semibold text-xl text-blueGray-700">
              {{ statTitle }}
            </span>
          </div>
          <div class="relative w-auto pl-4 flex-initial">
            <div
              class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full"
              :class="[statIconColor]"
            >
              <i :class="[statIconName]"></i>
            </div>
          </div>
        </div>
        <div class="flex justify-between items-center">
          <p class="text-sm text-blueGray-400">
            <span class="mr-2" :class="[computedStatPercentColor]">
              <i :class="[computedStatIcon]"></i>
              {{ formattedStatPercent }}%
            </span>
            <span class="whitespace-nowrap">{{ statDescription }}</span>
          </p>
          <!-- Suggestion Box (appears below card) -->
          <div v-if="showSuggestionBox" class="suggestion-box">
            <div class="flex justify-between items-center">
              <h5 :class="{'rainbow-gradient': showRainbowEffect}" class="font-bold text-lg">Suggesties</h5>
              <button type="button" class="text-gray-500 hover:text-gray-700" @click="closeSuggestionBox" aria-label="Close">
                <i class="fas fa-times"></i>
              </button>
            </div>
            <div class="suggestion-text mt-2">
              <p>{{ displayedSuggestion }}</p>
            </div>
          </div>
          <div class="flex justify-end items-center space-x-2">
            <!-- Magnifying Glass Icon Button -->
            <button
              type="button"
              @click="toggleModal"
              class="text-gray-500 hover:text-gray-700"
              style="margin-top:15px;margin-right:7px;"
            >
              <i class="fas fa-search fa-lg"></i>
            </button>

            <!-- Magic Wand Icon Button -->
            <button
              type="button"
              @click="togglesuggestionBox"
              class="text-gray-500 hover:text-gray-700"
              style="margin-top: 15px;margin-left:7px;"
            >
              <i class="fas fa-wand-magic-sparkles fa-lg"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Magnifying glass icon button -->

    <!-- Modal -->
    <custom-modal :is-visible.sync="showModal">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"><b>{{ modalTitle }}</b></h5>
            <button type="button" class="btn-close" @click="showModal = false" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <ul class="nav nav-tabs">
              <li class="nav-item" v-for="(value, name) in resultObject" :key="name">
                <a
                  :class="['nav-link', { active: activeTab === name }]"
                  @click.prevent="activeTab = name"
                  href="#"
                  role="tab"
                >{{ name }}</a>
              </li>
            </ul>
            <!-- Tab Content -->
            <div class="tab-content mt-3">
              <div v-for="(items, name) in resultObject" :key="name">
                <div v-if="activeTab === name">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" v-for="(header, index) in items[0]" :key="index">{{ header }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, rowIndex) in items.slice(1)" :key="rowIndex">
                        <td v-for="(cell, cellIndex) in row" :key="cellIndex">{{ cell }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="modal-close-btn" @click="showModal = false">{{ $t('graphs.close') }}</button>
          </div>
        </div>
      </div>
    </custom-modal>
  </div>
</template>

<script>
import CustomModal from './CustomModal.vue';
import { Skeleton } from 'vue-loading-skeleton';
import 'vue-loading-skeleton/dist/style.css';
import './custom-bootstrap.css';

export default {
  name: "card-stats",
  components: {
    CustomModal,
    Skeleton,
  },
  props: {
    statPercent: {
      type: [Number, String],
      default: 0,
    },
    backgroundColor: {
      type: String,
      default: "bg-white"
    },
    statSubtitle: {
      type: String,
      default: "Traffic",
    },
    statTitle: {
      type: String,
      default: "350,897",
    },
    statDescription: {
      type: String,
      default: "Since last month",
    },
    statIconName: {
      type: String,
      default: "far fa-chart-bar",
    },
    statIconColor: {
      type: String,
      default: "bg-red-500",
    },
    resultObject: {
      type: Object,
      default: () => ({})
    },
    modalId: {
      type: String,
      required: true
    },
    modalTitle: {
      type: String,
      default: "Details",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    cleanupSuggestion: {
      type: String,
      default: 'Text here',
    },
  },
  computed: {
    computedStatIcon() {
      const percent = parseFloat(this.statPercent);
      if (percent > 0) {
        return 'fas fa-arrow-up';
      } else if (percent < 0) {
        return 'fas fa-arrow-down';
      } else {
        return 'fas fa-minus';
      }
    },
    computedStatPercentColor() {
      const percent = parseFloat(this.statPercent);
      if (percent > 0) {
        return "text-red-500";
      } else if (percent < 0) {
        return "text-emerald-500";
      } else {
        return "text-gray-500";
      }
    },
    formattedStatPercent() {
      const percent = parseFloat(this.statPercent);
      return percent !== 0 ? percent.toString() : '-';
    }
  },
  methods: {
    getCleanupSuggestion() {
      // You can customize the suggestion based on the card's data here
      return this.cleanupSuggestion;
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    togglesuggestionBox() {
      this.showSuggestionBox = !this.showSuggestionBox;
      if (this.showSuggestionBox) {
        this.displayedSuggestion = ''; // Reset the displayed suggestion
        this.typeSuggestion();

      // Trigger rainbow effect
      this.showRainbowEffect = true;
      }
    },
    typeSuggestion() {
      let index = 0;
      const fullSuggestion = this.getCleanupSuggestion();
      const typingInterval = setInterval(() => {
        if (index < fullSuggestion.length) {
          this.displayedSuggestion += fullSuggestion[index];
          index++;
        } else {
          clearInterval(typingInterval); // Stop typing when finished
        }
      }, this.typingSpeed);
    },
    closeSuggestionBox() {
      this.showSuggestionBox = false;
    },
  },
  data() {
    return {
      showModal: false,
      showSuggestionBox: false,
      activeTab: '',
      displayedSuggestion: '', // For the typing effect
      showRainbowEffect: false, // Control the rainbow effect
      typingSpeed: 30, // Speed for the typing effect
    };
  },
  mounted() {
    this.activeTab = Object.keys(this.resultObject)[0] || 'overzicht';
  },
};
</script>

<style scoped>
/* Style for tabs */
.nav-tabs .nav-item {
  margin-bottom: -1px;
}
.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  color: #007bff;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .nav-link.active {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

/* Adjust alignment of the icons */
.flex-end-icons {
  display: flex;
  justify-content: flex-end;
  gap: 7px;
}

/* Suggestion Box Styling */
.suggestion-box {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999; /* Make sure it appears on top of other elements */
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 1rem;
  margin-top: 10px;
}

/* Typing effect font style */
.suggestion-text {
  white-space: pre-wrap;
}

/* Close Button with Soft Red Background */
.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  background-color: #ffcccc; /* Soft red */
  color: #333; /* Text color for contrast */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.modal-close-btn:hover {
  background-color: #ff9999; /* Slightly darker red on hover */
}

/* Spreadsheet-like table styles */
.table {
  width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
}

.table th, .table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

/* Softer rainbow gradient animation with smooth fading to black */
@keyframes rainbowGradient {
  0% {
    background-position: 100%;
    color: transparent;
  }
  80% {
    background-position: 0%;
    color: transparent;
  }
  100% {
    background-position: 0%;
    color: black; /* Gradually changes to black */
  }
}


.rainbow-gradient {
  background: linear-gradient(90deg, #ff6f61, #ffa07a, #fff176, #9acd32, #87cefa, #9370db, #dda0dd); /* Softer rainbow colors */
  background-size: 300%;
  -webkit-background-clip: text;
  background-clip: text;
  color: black; /* Fallback for browsers that don’t support background-clip */
  animation: rainbowGradient 3s ease forwards; /* Smooth transition to black */
}





</style>