<template>
  <div>
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6">
      <ReusableCardLineChart :chartTitle="$t('batch_results.evolution')" :chartSubtitle="$t('batch_results.file_size_mb')" :chartData="chartData" />
    </div>
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded"
      :class="[color === 'light' ? 'bg-white' : 'bg-emerald-900 text-white']"
    >
      <div class="px-4 py-3 border-b-0">
        <div class="flex flex-wrap items-center justify-between">
          <div class="relative w-full max-w-full flex-grow flex-1">
            <h3
              class="font-bold text-2xl"
              :class="[color === 'light' ? 'text-blueGray-700' : 'text-white']"
            >
              {{ $t('batch_results.title') }}
            </h3>
            <h2>{{ $t('batch_results.description') }}</h2>
          </div>
        </div>
      </div>

<!-- Dropdown for the upload form -->
<div class="dropdown-container" style="padding:20px;">
  <button class="dropdown-button" @click="toggleDropdown">
    {{ $t('batch_results.upload_new_batch') }} <i :class="dropdownOpen ? 'arrow-up' : 'arrow-down'"></i>
  </button>

  <!-- Upload Form in dropdown with transition -->
  <div :class="['upload-content-container', { open: dropdownOpen }]">
    <!-- Left part: Individual file uploads (Mosaiced) -->
    <div class="upload-individual">
      <h1 style="padding: 30px;"><b>{{ $t('batch_results.upload_individual_files') }}</b></h1>
      <div class="file-grid">
        <div class="file-input-group" :class="{ 'file-selected': docTypeSelected }">
          <label for="docType" class="styled-file-input">{{ $t('batch_results.document_types') }}</label>
          <input id="docType" type="file" @change="onFileChangeDocType" accept=".xlsx" class="hidden-file-input" />
              </div>
              <div class="file-input-group" :class="{ 'file-selected': auditSelected }">
                <label for="audit" class="styled-file-input">{{ $t('batch_results.audit') }}</label>
                <input id="audit" type="file" @change="onFileChangeAudit" accept=".xlsx" class="hidden-file-input" />
              </div>
              <div class="file-input-group" :class="{ 'file-selected': idDocsSelected }">
                <label for="idDocs" class="styled-file-input">{{ $t('batch_results.identical_documents') }}</label>
                <input id="idDocs" type="file" @change="onFileChangeIdDocs" accept=".xlsx" class="hidden-file-input" />
              </div>
              <div class="file-input-group" :class="{ 'file-selected': oldNewFoldersSelected }">
                <label for="oldNewFolders" class="styled-file-input">{{ $t('batch_results.old_new_folders') }}</label>
                <input id="oldNewFolders" type="file" @change="onFileChangeOldNewFolders" accept=".xlsx" class="hidden-file-input" />
              </div>
              <div class="file-input-group" :class="{ 'file-selected': cleanFoldersSelected }">
                <label for="cleanFolders" class="styled-file-input">{{ $t('batch_results.clean_folders') }}</label>
                <input id="cleanFolders" type="file" @change="onFileChangeCleanFolders" accept=".xlsx" class="hidden-file-input" />
              </div>
              <div class="file-input-group" :class="{ 'file-selected': zipFilesSelected }">
                <label for="zipFiles" class="styled-file-input">{{ $t('batch_results.unpacked_zip_files') }}</label>
                <input id="zipFiles" type="file" @change="onFileChangeZIPFiles" accept=".xlsx" class="hidden-file-input" />
              </div>
              <div class="file-input-group" :class="{ 'file-selected': secureDocsSelected }">
                <label for="secureDocs" class="styled-file-input">{{ $t('batch_results.secure_documents') }}</label>
                <input id="secureDocs" type="file" @change="onFileChangeSecureDocs" accept=".xlsx" class="hidden-file-input" />
              </div>
              <div class="file-input-group" :class="{ 'file-selected': fileFormatsSelected }">
                <label for="fileFormats" class="styled-file-input">{{ $t('batch_results.incorrect_file_formats') }}</label>
                <input id="fileFormats" type="file" @change="onFileChangeFileFormats" accept=".xlsx" class="hidden-file-input" />
              </div>
              <div class="file-input-group" :class="{ 'file-selected': shortcutsSelected }">
                <label for="shortcuts" class="styled-file-input">{{ $t('batch_results.checked_shortcuts') }}</label>
                <input id="shortcuts" type="file" @change="onFileChangeShortcuts" accept=".xlsx" class="hidden-file-input" />
              </div>
            </div>
          </div>

        <!-- Divider with 'OF' and vertical line -->
        <div class="divider">
          <span>{{ $t('batch_results.or') }}</span>
        </div>

<!-- Right part: Zip upload -->
<div class="upload-zip">
  <h1 style="padding: 30px; display: flex; align-items: center; justify-content: center;">
    <!-- Information Icon -->
    <div class="info-button-container">
      <div class="info-icon">i</div>
      <div class="tooltip-text">
        {{ $t('batch_results.zip_filename_requirements') }}
      </div>
    </div>
    <b style="margin-left: 10px;">{{ $t('batch_results.upload_zip_file') }}</b> <!-- Small margin to add space between icon and text -->
  </h1>
  <div class="file-input-group" :class="{ 'file-selected': zipFileSelected }">
    <label for="zipFile" class="styled-file-input-zip">{{ $t('batch_results.upload_zip') }}</label>
    <input id="zipFile" type="file" @change="onFileChangeZip" accept=".zip" class="hidden-file-input" />
  </div>
</div>

        <!-- Button for uploading results -->
        <div class="upload-button-container">
          <button
            class="bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
            @click="uploadFiles"
          >
            {{ $t('batch_results.upload_results') }}
          </button>
        </div>
        </div>
        </div>
      <!-- End of Dropdown -->

      <div class="px-4 py-3 border-b-0">
        <div class="flex flex-wrap items-center justify-between">
          <div class="w-full">
            <table class="items-center w-full bg-transparent border-collapse">
              <thead>
                <tr>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    {{ $t('batch_results.checked_folder') }}
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    {{ $t('batch_results.date') }}
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    {{ $t('batch_results.time') }}
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  >
                    {{ $t('batch_results.num_files') }}
                  </th>
                  <th
                    class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left"
                    :class="[
                      color === 'light'
                        ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                        : 'bg-emerald-800 text-emerald-300 border-emerald-700',
                    ]"
                  ></th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="loading">
                  <td style="padding: 5px;">
                    <Skeleton count="5" />
                  </td>
                  <td style="padding: 5px;">
                    <Skeleton count="5" />
                  </td>
                  <td style="padding: 5px;">
                    <Skeleton count="5" />
                  </td>
                  <td style="padding: 5px;">
                    <Skeleton count="5" />
                  </td>
                  <td style="padding: 5px;">
                    <Skeleton count="5" />
                  </td>
                </tr>
                <tr v-else v-for="(item, index) in data" :key="index">
                  <td style="padding-bottom: 10px; padding-top: 15px;">{{ item.data[0][Object.keys(item.data[0])]['overzicht'][1][1] }}</td>
                  <td>{{ formatDate(item.batch_upload_date) }}</td>
                  <td>{{ formatTime(item.batch_upload_date) }}</td>
                  <td style="padding-left: 50px;">{{ item.data.length }}</td>
                  <td>
                    <button
                      class="bg-emerald-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                      @click="$router.push({ name: 'result', params: { batchId: item.batch_id } })"
                    >
                      <i class="fa fa-magnifying-glass-chart"></i>
                    </button>
                    <button
                      class="bg-red-500 text-white font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150"
                      @click="deleteItem(item.batch_id)"
                    >
                      <i class="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  import JSZip from 'jszip';
  import ReusableCardLineChart from "@/components/Cards/ReusableCardLineChart.vue";
  import { Skeleton } from 'vue-loading-skeleton';
  import "vue-loading-skeleton/dist/style.css"

  export default {
    data() {
      return {
        dropdownOpen: false,
        docTypeSelected: false,
        auditSelected: false,
        idDocsSelected: false,
        oldNewFoldersSelected: false,
        cleanFoldersSelected: false,
        zipFilesSelected: false,
        secureDocsSelected: false,
        fileFormatsSelected: false,
        shortcutsSelected: false,
        zipFileSelected: false,
        file: null,
        zipFile: null,
        text: '',
        fileTopics: [],
        isLoading: false,
        loading: true, // Add this line
        data: null,
        chart: null,
        chartData: {
          labels: [],
          datasets: [
            
          ]
        },
        lastBatchDatapoints: {},
      };
    },
    mounted() {
      const collectionId = this.$route.query.collection_id;
      if (collectionId) {
        this.fetchData(collectionId);
      }
    },
    methods: {
      toggleDropdown() {
      this.dropdownOpen = !this.dropdownOpen;
    },
      // Handle ZIP file change
      onFileChangeZip(event) {
        this.zipFile = event.target.files[0];
        this.zipFileSelected = !!event.target.files.length;
      },
      // Process and extract files from ZIP
// Process and extract files from ZIP
async extractFilesFromZip() {
    if (!this.zipFile) return;

    const zip = new JSZip();
    const content = await zip.loadAsync(this.zipFile);
    let files = [];

    // Iterate through each file in the ZIP
    await Promise.all(Object.keys(content.files).map(async (filename) => {
        // Skip temporary files that start with '~'
        if (filename.startsWith('~') || filename.startsWith('__')) {
            console.log(`Skipping temporary file: ${filename}`);
            return; // Skip processing this file
        }

        const fileData = await content.files[filename].async('blob');  // Extract the blob

        // Convert blob to File with correct MIME type and filename
        const file = new File([fileData], filename, { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        // Assign the file to the correct result field based on filename
        if (filename.toLowerCase().includes("types")) {
            this.DocTypeResult = file;
        } else if (filename.toLowerCase().includes("oude") || filename.toLowerCase().includes("nieuwe")) {
            this.OldNewFoldersResult = file;
        } else if (filename.toLowerCase().includes("audit")) {
            this.AuditResult = file;
        } else if (filename.toLowerCase().includes("identieke")) {
            this.IdDocsResult = file;
        } else if (filename.toLowerCase().includes("schonen")) {
            this.CleanFoldersResult = file;
        } else if (filename.toLowerCase().includes("beveiligde")) {
            this.SecureDocsResult = file;
        } else if (filename.toLowerCase().includes("format")) {
            this.FileFormatsResult = file;
        } else if (filename.toLowerCase().includes("zip")) {
            this.ZIPFilesResult = file;
        } else if (filename.toLowerCase().includes("snelkoppel")) {
            this.ShortcutsResult = file;
        }

        files.push(file); // Collect all files for debugging/logging if necessary
    }));

    console.log(files); // Log all processed files
},
      generateChart(data) {
          const chartData = {
              labels: [],
              datasets: [{
                  label: Object.keys(data)[0],
                  backgroundColor: "#ed64a6",
                  borderColor: "#ed64a6",
                  data: [],
                  fill: false,
                  barThickness: 8,
              }],
          };

          // Extract the data from the object and populate the chartData
          console.log(data);
          for (const item of data[Object.keys(data)[0]]) {
            console.log(item);
            if (isFinite(item[1]) && item[0].startsWith("Aantal documenten van het type") && item[0] !== "Aantal documenten van het type '*'") {
                chartData.labels.push(item[0]);
                chartData.datasets[0].data.push(Number(item[1]));
            }
          }

          this.chartData = chartData;
          console.log(this.chartData);
      },
      formatDate(datetime) {
        const date = new Date(datetime);
        return date.toLocaleDateString();
      },
      formatTime(datetime) {
        const date = new Date(datetime);
        return date.toLocaleTimeString();
      },
      onFileChangeDocType(event) {
      this.DocTypeResult = event.target.files[0];
      this.docTypeSelected = !!event.target.files.length;
      },
      onFileChangeAudit(event) {
        this.AuditResult = event.target.files[0];
        this.auditSelected = !!event.target.files.length;
      },
      onFileChangeIdDocs(event) {
        this.IdDocsResult = event.target.files[0];
        this.idDocsSelected = !!event.target.files.length;
      },
      onFileChangeOldNewFolders(event) {
        this.OldNewFoldersResult = event.target.files[0];
        this.oldNewFoldersSelected = !!event.target.files.length;
      },
      onFileChangeCleanFolders(event) {
        this.CleanFoldersResult = event.target.files[0];
        this.cleanFoldersSelected = !!event.target.files.length;
      },
      onFileChangeZIPFiles(event) {
        this.ZIPFilesResult = event.target.files[0];
        this.zipFilesSelected = !!event.target.files.length;
      },
      onFileChangeSecureDocs(event) {
        this.SecureDocsResult = event.target.files[0];
        this.secureDocsSelected = !!event.target.files.length;
      },
      onFileChangeFileFormats(event) {
        this.FileFormatsResult = event.target.files[0];
        this.fileFormatsSelected = !!event.target.files.length;
      },
      onFileChangeShortcuts(event) {
        this.ShortcutsResult = event.target.files[0];
        this.shortcutsSelected = !!event.target.files.length;
      },
      extractDataPoints(inputData) {
        console.log(inputData);

        inputData[0].data.forEach(item => {
          console.log(item);
          const filename = Object.keys(item)[0]; // Get the filename (e.g., "DocTypes.xlsx")
          console.log(filename);
          const fileData = item[filename]; // Get the corresponding data for the filename

          // Process based on filename
          if (filename === "DocTypes.xlsx" && fileData) {
            console.log("DocTypes.xlsx found");
            const overzichtGeneral = fileData.overzicht;
            const totaleOmvang = overzichtGeneral[7][1];
            const totaalDoorzochteMappen = overzichtGeneral[5][1];
            const aantalDocumenten = overzichtGeneral[6][1];

            this.lastBatchDatapoints.totaleOmvang = parseFloat(totaleOmvang.split(" ")[0]);
            this.lastBatchDatapoints.totaalDoorzochteMappen = parseInt(totaalDoorzochteMappen);
            this.lastBatchDatapoints.aantalDocumenten = parseInt(aantalDocumenten);
          }

          if (filename === "FileFormats.xlsx" && fileData) {
            console.log("FileFormats.xlsx found");
            const wrongExtensions = fileData.verkeerdeextensie;
            const wrongExtensionsAmount = wrongExtensions.length - 1;

            this.lastBatchDatapoints.wrongExtensionsAmount = parseInt(wrongExtensionsAmount);
          }

          if (filename === "Shortcuts.xlsx" && fileData) {
            console.log("Shortcuts.xlsx found");
            const snelkoppelingen = fileData.ongeldigesnelkoppelingen;
            const snelkoppelingenAmount = snelkoppelingen.length - 1;

            this.lastBatchDatapoints.snelkoppelingenAmount = parseInt(snelkoppelingenAmount);
          }

          if (filename === "SecureDocs.xlsx" && fileData) {
            console.log("SecureDocs.xlsx found");
            let passwordCount = 0;
            fileData.internebeveiliging.forEach(item => {
              if (item[2].includes("beveiligd (wachtwoord voor openen)")) {
                passwordCount++;
              }
            });

            this.lastBatchDatapoints.passwordCount = parseInt(passwordCount);
          }

          if (filename === "CleanFolders.xlsx" && fileData) {
            const overzichtSchonen = fileData.overzicht;
            const corruptEntry = overzichtSchonen.find(entry => entry[0] === "Documenten in corrupt formaat");
            const corruptFormat = corruptEntry ? corruptEntry[1] : 0;
            const legeMappen = overzichtSchonen[11][1];
            const documentenMetzelfdeNaam = overzichtSchonen[12][1];

            this.lastBatchDatapoints.corruptFormat = parseInt(corruptFormat);
            this.lastBatchDatapoints.legeMappen = parseInt(legeMappen);
            this.lastBatchDatapoints.documentenMetzelfdeNaam = parseInt(documentenMetzelfdeNaam);
          }

          if (filename === "IdDocs.xlsx" && fileData) {
            const overzichtIDDocs = fileData.overzicht;
            const totalIDDocs = overzichtIDDocs[5][1];
            const totalIDNameDocs = overzichtIDDocs[6][1];

            // Identical documents processing
            const concatIDDocNames = fileData['identiekedocumentnaam'].map(obj => obj[0] + obj[1]).slice(1);
            const concatIDDocs = fileData['identiekeinhoud'].map(obj => obj[0] + obj[1]).slice(1);
            const completelyIDDocs = concatIDDocNames.filter(value => concatIDDocs.includes(value));
            const completelyIDDocsAmount = completelyIDDocs.length - 1;

            this.lastBatchDatapoints.totalIDDocs = parseInt(totalIDDocs);
            this.lastBatchDatapoints.totalIDNameDocs = parseInt(totalIDNameDocs);
            this.lastBatchDatapoints.completelyIDDocsAmount = parseInt(completelyIDDocsAmount);
          }
        });

        // Logging the extracted values
        console.log(this.lastBatchDatapoints);
      },

      extractBatchData(inputData) {
        if (!inputData || inputData.length === 0) {
          console.log("No previous batch data available to extract datapoints from.");
          return; // Exit the method if there's no data to process
        }
            this.extractDataPoints(inputData);
            // Extract and transform data
            let transformedData = inputData.map(item => {
                const batchDate = item.batch_upload_date.split('T')[0]; // Extract only the date part
                console.log(item.data[0]);
                const overzicht = item.data[0]['DocTypes.xlsx'].overzicht;
                console.log(overzicht);
                const sizeEntry = overzicht.find(entry => entry['0'].includes("Totale omvang van de documenten"));
                console.log(sizeEntry);

                let size = 0;
                if (sizeEntry) {
                    // Replace comma with period and trim whitespace
                    let sizeStr = sizeEntry['1'].replace(',', '.').trim(); // "1.95 GB"
                    // Split the string into value and unit
                    let [valueStr, unit] = sizeStr.split(' '); // ["1.95", "GB"]
                    let value = parseFloat(valueStr);
                    // Convert GB to MB if necessary
                    if (unit === 'GB') {
                        value = value * 1024;
                    }
                    // Round the value to an integer
                    size = Math.round(value);
                }
                console.log(size);
                return [batchDate, size];
            });

            // Sort by date (earliest first)
            //transformedData.sort((a, b) => new Date(a[0]) - new Date(b[0]));
            transformedData.reverse();

            // Create labels and dataset structure for chartData
            const chartData = {
                labels: transformedData.map(item => item[0]),
                datasets: [
                    {
                        label: this.$t('graphs.totalsize_mb'),
                        data: transformedData.map(item => item[1]),
                        backgroundColor: 'rgba(54, 162, 235, 0.5)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        borderWidth: 1
                    }
                ]
            };
            console.log(chartData);
            return chartData;
      },
      async uploadFiles() {
        let formData = new FormData();

        // Handle ZIP extraction if a ZIP file was uploaded
        if (this.zipFile) {
          await this.extractFilesFromZip();
        }

        // Rename and append each file with the new desired filename
        if (this.DocTypeResult) {
          const renamedDocType = new File([this.DocTypeResult], 'DocTypes.xlsx', { type: this.DocTypeResult.type });
          formData.append('doctype', renamedDocType);
        }

        if (this.AuditResult) {
          const renamedAudit = new File([this.AuditResult], 'Audit.xlsx', { type: this.AuditResult.type });
          formData.append('audit', renamedAudit);
        }

        if (this.IdDocsResult) {
          const renamedIdDocs = new File([this.IdDocsResult], 'IdDocs.xlsx', { type: this.IdDocsResult.type });
          formData.append('iddocs', renamedIdDocs);
        }

        if (this.OldNewFoldersResult) {
          const renamedOldNewFolders = new File([this.OldNewFoldersResult], 'OldNewFolders.xlsx', { type: this.OldNewFoldersResult.type });
          formData.append('oldnewfolders', renamedOldNewFolders);
        }

        if (this.CleanFoldersResult) {
          const renamedCleanFolders = new File([this.CleanFoldersResult], 'CleanFolders.xlsx', { type: this.CleanFoldersResult.type });
          formData.append('cleanfolders', renamedCleanFolders);
        }

        if (this.ZIPFilesResult) {
          const renamedZIPFiles = new File([this.ZIPFilesResult], 'ZIPFiles.xlsx', { type: this.ZIPFilesResult.type });
          formData.append('zipfiles', renamedZIPFiles);
        }

        if (this.SecureDocsResult) {
          const renamedSecureDocs = new File([this.SecureDocsResult], 'SecureDocs.xlsx', { type: this.SecureDocsResult.type });
          formData.append('securedocs', renamedSecureDocs);
        }

        if (this.FileFormatsResult) {
          const renamedFileFormats = new File([this.FileFormatsResult], 'FileFormats.xlsx', { type: this.FileFormatsResult.type });
          formData.append('fileformats', renamedFileFormats);
        }

        if (this.ShortcutsResult) {
          const renamedShortcuts = new File([this.ShortcutsResult], 'Shortcuts.xlsx', { type: this.ShortcutsResult.type });
          formData.append('shortcuts', renamedShortcuts);
        }

        // Assuming 'currentCollectionId' holds the ID of the collection you want to associate the batch with
        const currentCollectionId = this.$route.query.collection_id;
        formData.append('collection_id', currentCollectionId);

        // Only append lastBatchDatapoints if it's not empty
        if (Object.keys(this.lastBatchDatapoints).length > 0) {
          formData.append('lastBatchDatapoints', JSON.stringify(this.lastBatchDatapoints));
        }

        console.log(formData);

        const token = localStorage.getItem('user-token');
        axios.post('https://conan.antsolutions.be/conanbe/uploadRMTResults/', formData, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            console.log(response);
              // After uploading the files, reset the selection states
            this.docTypeSelected = false;
            this.auditSelected = false;
            this.idDocsSelected = false;
            this.oldNewFoldersSelected = false;
            this.cleanFoldersSelected = false;
            this.zipFilesSelected = false;
            this.secureDocsSelected = false;
            this.fileFormatsSelected = false;
            this.shortcutsSelected = false;
            this.zipFileSelected = false;

            // Optionally, also reset the file references
            this.DocTypeResult = null;
            this.AuditResult = null;
            this.IdDocsResult = null;
            this.OldNewFoldersResult = null;
            this.CleanFoldersResult = null;
            this.ZIPFilesResult = null;
            this.SecureDocsResult = null;
            this.FileFormatsResult = null;
            this.ShortcutsResult = null;
            this.zipFile = null;
            const collectionId = this.$route.query.collection_id;
            this.fetchData(collectionId);  // Refresh the data after uploading
          })
          .catch(error => {
            console.error(error);
          });
      },

      fetchData(collectionId = null) {
        this.loading = true; // Set loading to true before making the request
        let url = 'https://conan.antsolutions.be/conanbe/fetchExcelResults/';
        if (collectionId) {
          url += `?collection_id=${collectionId}`;
        }
        const token = localStorage.getItem('user-token');
        axios.get(url, {
          headers: {
            Authorization: `Token ${token}`,
            'Content-Type': 'multipart/form-data'
          }})
          .then(response => {
            // Check if response.data is a string, if so, perform replacement and parsing
            let correctedDataString;
            if (typeof response.data === 'string') {
                correctedDataString = response.data.replace(/\bNaN\b/g, '"NaN"');
            } else {
                // If it's already an object, skip the replacement
                correctedDataString = response.data;
            }

            // Check if correctedDataString is a string and needs parsing
            let parsedData;
            if (typeof correctedDataString === 'string') {
                parsedData = JSON.parse(correctedDataString);
            } else {
                parsedData = correctedDataString;
            }
            console.log(parsedData);
            this.data = parsedData;
            this.chartData = this.extractBatchData(parsedData);
            console.log(this.extractBatchData(parsedData));
            this.loading = false; // Set loading to false after data is processed
          })
          .catch(error => {
            console.error('Error fetching data:', error);
            this.loading = false; // Set loading to false even if there's an error
          });
      },
      async deleteItem(batchId) {
        try {
          const token = localStorage.getItem('user-token');
          const response = await axios.delete(`https://conan.antsolutions.be/conanbe/deleteBatch/${batchId}`, {
            headers: {
              Authorization: `Token ${token}`,
              'Content-Type': 'multipart/form-data'
            }
          });

          if (response.status === 200) {
            // Ensure items exists and is an array
            if (Array.isArray(this.items)) {
              this.items = this.items.filter(item => item.batch_id !== batchId);
            }
            const collectionId = this.$route.query.collection_id;
            if (collectionId) {
              this.fetchData(collectionId);
            }
          } else {
            alert('Failed to delete item');
          }
        } catch (error) {
          console.error('Error deleting item:', error);
          alert(error);
        }
      },
    },
    created() {
      //this.fetchData();  // Fetch the data when the component is created
    },
    components: {
      Skeleton,
      ReusableCardLineChart,
    },
    props: {
      color: {
        default: "light",
        validator: function (value) {
          // The value must match one of these strings
          return ["light", "dark"].indexOf(value) !== -1;
        },
      },
    },
  };
  </script>
<style>
/* Info button (i-in-a-circle) */
/* Info button (i-in-a-circle) */
.info-button-container {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.info-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #007bff;
  color: white;
  text-align: center;
  line-height: 20px;
  font-weight: bold;
  font-size: 14px;
  cursor: pointer;
}

/* Tooltip styling */
.tooltip-text {
  visibility: hidden;
  font-size: 0.75rem;
  width: 300px;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  top: 125%; /* Move the tooltip below the icon */
  left: 50%;
  transform: translateX(-50%); /* Center the tooltip below the icon */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow (below the tooltip) */
.tooltip-text::after {
  content: "";
  position: absolute;
  bottom: 100%; /* Arrow on the top of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

/* Show the tooltip on hover */
.info-button-container:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}
.upload-content-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap; /* Allows the button to fit better in smaller screens */
  background-color: #f8f9fa; /* Soft white/grey background */
  padding: 20px;
  border-radius: 8px;
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out; /* Transition for smooth opening and closing */
}

.upload-content-container.open {
  opacity: 1;
  max-height: 1000px; /* Set to a large enough value to ensure full content display */
}

.upload-button-container {
  display: flex;
  justify-content: center; /* Center the button horizontally */
  width: 100%; /* Ensure the button container takes up the full width */
  margin-top: 50px; /* Adds spacing above the button */
}

.upload-individual {
  width: 45%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.file-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}

.upload-zip {
  width: 45%;
  text-align: center;
}

.file-input-group {
  text-align: center;
}

.styled-file-input {
  display: inline-block;
  background-color: #5DADE2;;
  color: #fff;
  padding: 6px 10px; /* Smaller size */
  font-size: 10px; /* Smaller font */
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  height: 100%;
  transition: background-color 0.2s;
}

.styled-file-input-zip {
  display: inline-block;
  background-color: #5DADE2;;
  color: #fff;
  padding: 6px 10px; /* Smaller size */
  font-size: 10px; /* Smaller font */
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  width: 50%;
  height: 100%;
  transition: background-color 0.2s;
}

.styled-file-input:hover {
  background-color: #0056b3;
}

.styled-file-input-zip:hover {
  background-color: #0056b3;
}

.hidden-file-input {
  display: none;
}

.file-selected .styled-file-input {
  background-color: #28a745;
}

.file-selected .styled-file-input-zip {
  background-color: #28a745;
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 5%;
  margin-top: 3%;
}

.divider span {
  font-size: 18px;
  font-weight: bold;
  color: #999;
  background-color: #f8f9fa;
  padding: 10px;
  z-index: 1;
}

.divider::before {
  content: '';
  position: absolute;
  left: 50%;
  height: 100%;
  width: 1px;
  background-color: #ddd;
  z-index: 0;
}

.dropdown-container {
  margin-bottom: 0px;
}

.dropdown-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.arrow-down {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
}

.arrow-up {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
}
</style>