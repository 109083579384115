<template>
  <div
    class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
  >
    <div class="rounded-t bg-white mb-0 px-6 py-6">
      <div class="text-center flex justify-between">
        <h6 class="text-blueGray-700 text-xl font-bold">{{ $t('profile.your_profile') }}</h6>
      </div>
    </div>
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ $t('profile.details') }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ $t('profile.username') }}
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                v-model="userData.username"
                readonly
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ $t('profile.email') }}
              </label>
              <input
                type="email"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                v-model="userData.email"
                readonly
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ $t('profile.first_name') }}
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                v-model="userData.first_name"
                readonly
              />
            </div>
          </div>
          <div class="w-full lg:w-6/12 px-4">
            <div class="relative w-full mb-3">
              <label class="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                {{ $t('profile.last_name') }}
              </label>
              <input
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                v-model="userData.last_name"
                readonly
              />
            </div>
          </div>
        </div>

        <!-- New section to show the user's groups -->
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ $t('profile.groups') }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <ul>
                <li v-for="group in userData.groups" :key="group" class="bg-white rounded p-2 my-2 shadow">
                  {{ group }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <!-- Language Selector -->
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">{{ $t('profile.language') }}</h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <button
                :class="{'active-lang': userData.language === 'nl', 'inactive-lang': userData.language !== 'nl'}"
                @click="updateLanguage('nl')"
                type="button"  
                class="lang-button mr-2"
              >
                {{ $t('profile.dutch') }}
              </button>

              <button
                :class="{'active-lang': userData.language === 'fr', 'inactive-lang': userData.language !== 'fr'}"
                @click="updateLanguage('fr')"
                type="button"  
                class="lang-button"
              >
                {{ $t('profile.french') }}
              </button>
            </div>
          </div>
        </div>

        <hr class="mt-6 border-b-1 border-blueGray-300" />

        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          {{ $t('profile.sharepoint_connection') }}
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-12/12 px-4">
            <div class="relative w-full mb-3">
              <button class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button" v-if="!isLoggedIn" @click="login">{{ $t('profile.microsoft_login') }}</button>
              <div v-else>
                <p>{{ $t('profile.logged_in_as') }} {{ userData.displayName }}</p> <br/>
                <button class="bg-red-500 text-white active:bg-red-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150" @click="logout">{{ $t('profile.logout') }}</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import i18n from '@/i18n';

export default {
  data() {
    return {
      userData: {
        username: '',
        email: '',
        first_name: '',
        last_name: '',
        groups: [],
        language: ''  // Default to Dutch if no language set
      },
      isLoggedIn: false,
    };
  },
  methods: {
    async checkLoginStatus() {
      try {
        const token = localStorage.getItem('user-token'); // Ensure the token is fetched from storage
        const response = await axios.get('https://conan.antsolutions.be/conanbe/getUserInfo/', {
          headers: {
            Authorization: `Token ${token}`, // Include token in the headers
          },
          withCredentials: true,
        });
        if (response.data.displayName) {
          this.isLoggedIn = true;
          this.userData = response.data;
        } else {
          this.isLoggedIn = false;
          this.userData = null;
        }
      } catch (error) {
        console.error(error);
        this.isLoggedIn = false;
      }
    },
    logout() {
      axios
        .get('https://conan.antsolutions.be/conanbe/logoutMS/', { withCredentials: true })
        .then(() => {
            this.isLoggedIn = false;
        })
        .catch((error) => {
            console.error(error);
        });
    },
    login() {
      window.location.href = 'https://conan.antsolutions.be/conanbe/loginMS/';
    },
    getUserDetails() {
      const token = localStorage.getItem('user-token');
      axios.get('https://conan.antsolutions.be/conanbe/getCurrentUserDetails', {
        headers: { Authorization: `Token ${token}` }
      })
        .then((response) => {
          console.log(response.data);
          this.userData = response.data;
        })
        .catch((error) => {
          console.error('Error fetching user details:', error);
        });
    },
    updateLanguage(language) {
  const token = localStorage.getItem('user-token');
  console.log(language);
  this.userData.language = language; // Update frontend immediately for visual feedback
  i18n.global.locale = language; // Update the i18n instance to use the new language
  
  axios.post('https://conan.antsolutions.be/conanbe/updateLanguage/', {
    language: language
  }, {
    headers: { Authorization: `Token ${token}` }
  }).then(() => {
    console.log('Language updated');
    // Force route to re-render
    this.$router.go(0); // This forces the page to re-render with the new locale
  }).catch(error => {
    console.error('Error updating language:', error);
  });
    },
  },
  created() {
    this.getUserDetails();
    this.checkLoginStatus();
  },
  watch: {
  '$i18n.locale': function(newLocale) {
    // React to the language change.
    console.log('Language changed to:', newLocale);
  }
},
};
</script>

<style scoped>
/* Language button styles */
.lang-button {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.15s ease;
  border: none;
  cursor: pointer;
}

/* Active and inactive language states */
.active-lang {
  background-color: #2d3748; /* Darker gray color for active button */
}

.inactive-lang {
  background-color: #e2e8f0; /* Lighter gray color for inactive button */
  color: #4a5568; /* Darker text color for contrast on inactive button */
}

/* Hover effects for buttons */
.lang-button:hover {
  background-color: #4a5568; /* Hover state for both buttons */
  color: white;
}

.mr-2 {
  margin-right: 10px;
}
</style>
